import { Input } from '@SRHealth/frontend-lib';
import React, { useMemo, useState } from 'react';
import { isRideTimeString, type RideType } from '~/models';
import { useAppSelector } from '~/Modules';
import { useTimeOptions } from './RidesTimePicker.utils';
import { selectLastRideTime } from '~/Modules/rideBooking';

export type RidesTimePickerProps = {
  inputId: number;
  type: RideType;
  time: string | undefined;
  timezone: string | undefined;
  error: boolean;
  onChange: (inputId: number, time: string) => void;
};

export default function RidesTimePicker({
  inputId,
  type,
  time,
  timezone,
  error,
  onChange
}: RidesTimePickerProps) {
  const [query, setQuery] = useState('');
  const lastRideTime = useAppSelector(s => selectLastRideTime(s));
  const timeRestrictions = useAppSelector(s => s.rideBooking.meta.timeRestrictions);
  const timeOptions = useTimeOptions(type, timezone, timeRestrictions);

  // Don't allow the user to select a time earlier than the last ride time.
  const _timeOptions = useMemo(() => {
    if (!lastRideTime || !isRideTimeString(lastRideTime)) return timeOptions;

    const optIndex = timeOptions.findIndex(({ value }) => value === lastRideTime);
    const withFlex = timeOptions.findIndex(({ value }) => value === 'Flex Time');

    return withFlex > -1
      ? [timeOptions[withFlex]].concat(timeOptions.slice(optIndex + 1))
      : timeOptions.slice(optIndex + 1);
  }, [lastRideTime, timeOptions]);

  const label = type === 'arriveBy' ? 'Appointment Time' : 'Depart Pick Up Time';

  return (
    <Input
      inputId={inputId}
      type="search-dropdown"
      name="time"
      label={label}
      placeholder="Select a time"
      icon="Time"
      loading={!timezone}
      value={time}
      options={_timeOptions.filter(({ label }) => label.includes(query))}
      debounce={650}
      onInput={(_, query) => setQuery(query)}
      onSearch={(_, query) => setQuery(query)}
      onChange={onChange}
      disabled={!timezone}
      error={error}
      required
    />
  );
}
