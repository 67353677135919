import { token } from '~/utilities/auth.helper';
import type { ApiScheme } from '~/types';

export interface ApiSchemaApprovedProvider extends ApiScheme {
  Read: {
    Method: 'GET';
    Request: {
      search: string;
      hospitalId: number;
      offset?: number;
      limit?: number;
      page?: number;
    };
    Response: {
      data: BE.ApprovedProvider[];
      environment: string;
      errors: Record<string, unknown>[];
      httpCode: number;
      message: string;
      status: boolean;
      timestamp: string;
    };
  };
}

/**
 * Query the approved providers using a search string.
 * @param search
 * @param hospitalId
 * @param offset
 * @param limit
 * @returns
 */
export const getApprovedProviders = async (
  search: string,
  hospitalId: number,
  offset = 0,
  limit = 20,
  page = 0
): Promise<ApiSchemaApprovedProvider['Read']['Response']> => {
  const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/approved-providers/${hospitalId}`;

  const urlParams = new URLSearchParams({
    search,
    offset: offset.toString(),
    limit: limit.toString(),
    page: page.toString()
  });

  return fetch(`${url}?${urlParams}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token()}`,
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());
};
